var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"anchors-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"tags":_vm.tariffTag,"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.tariffTag)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),(!_vm.tariffTag)?[_c('list',{staticClass:"mt-5",attrs:{"config":{
        'data': _vm.anchors,
        'paginateBy': 5,
        'searchFn': function (item, index, text) { return item.value.trigger.includes(text.trim()); },
        'keyFn': function (item) { return item.guid; },
        'direction': _vm.ListDirection.Vertical
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('anchor-card',{on:{"delete":function($event){return _vm.deleteAnchor(item.guid)},"input":_vm.onAnchorsChange},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,false,1112833085)},[_c('a-button',{attrs:{"slot":"title","type":"primary","icon":"plus"},on:{"click":_vm.createNewAnchor},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('anchor_create_new'))+" ")])],1)]:_c('not-available-options-overlay',{staticClass:"shadow relative",staticStyle:{"margin":"0 1rem"},attrs:{"tags":_vm.tariffTag,"video":_vm.$i18n.locale !== 'ru' ?
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_anchors_en.m4v' :
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_anchors_ru.m4v',"description":_vm.$t('option_description_anchors')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }